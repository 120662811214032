// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";

import moment from "moment";
export interface ColumnProjects {
  id: number;
  label: string;
  key: string;
}

interface ReportItem {
  report_id: number;
  report_name: string;
  date_created: string;
  quote_uploaded: string;
  invoice_uploaded: string;
  last_modified: string;
  no_of_invoices: number;
  status_invoices: [boolean, number][];
  total_discrepancy: number;
  idx: number;
  last_email_sent_at: string;
}


import { setStorageData ,getStorageData} from "../../../framework/src/Utilities";

type KeyMappings = {
  [key: string]: string;
};
export const keyMappings: KeyMappings = {
  "Report Name": "report_name",
  "Date Created": "date_created",
  "Quote Uploaded":"quote_uploaded",
  "Invoice Uploaded": "invoice_uploaded",
  "Last Modified":"last_modified",
  "No. of Invoices": "no_of_inovices",
  "Total Discrepancy": "total_discrepancy"
};

export interface FilterDialogProps {
  classes: any,
  daterangeOptionsData: string[],
  openDialog:boolean,
  StatusOptionsData: string[],
  vendorListData: string[]
  handleCustomFieldValue:any,
  handleClose: () => void,
  handleOpen: ()=>void,
  vendorName: string,
  handleSliderChange: (event: React.ChangeEvent<{}>, newValue: any) => void,
  handleInvoiceSliderChange:(event: React.ChangeEvent<{}>, value: number | number[]) => void,
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  truncateTextList:(value:string) =>  string ,
  sliderValue: number[],
  sliderInvoiceValue:any,
  isCustomValue: any,
  statusValue: string,
  MaxSliderVal: string | number,
  MinSliderVal: string | number,
  dateVal: string,
  toDateVal: string,
  endDateDisabledVal: boolean,
  handleDateChange: (event: React.ChangeEvent<{ value: string | unknown }>) => void
  handleVendorChange: (event: React.ChangeEvent<{ value: string | unknown }>) => void
  handleMinSliderChange: (values: any) => void
  handleMaxSliderChange: (values: any) => void
  filterApplyHandler: () => void
  handleFromDateChange: (event: Date | null) => void
  handleToDateChange: (event: Date | null) => void
  resetAllHandler: () => void
  minNumberSilder: number  
  maxNumberSilder: number 
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  
  classes: any;
  navigation: any;
  handleSaveData: (data: any) => void;
  handleSearchreport:(data:any)=>void;
  handleDateRangeModal:() => void;
  history: any;
  hasDateRange: boolean;
  handleDateRangeValue: (event:any) => void;
  handleCheckboxChange:(event:any ) => void,

  // Customizable Area End
}

 interface ColumnProject {
  id: any;
  label: any;
  key:string;
}

interface S {
  // Customizable Area Start
  reportList: any,
  columnsProject: ColumnProject[],
  deleteReportId: number | null,
  //Filter TypeScript Data
  minNumberSilder:any,
  openDialog: boolean,
  vendorName:string,
  setAnchorEl: null | HTMLElement;
  vendorListData: string[],
  StatusOptionsData:string[],
  dateVal:string,
  endDateDisabledVal:boolean,

  reportProjects:[],
  MinSliderVal: number|string,
  sliderValue: number[],

  dataChipArray: {label:string | number,key:number}[],
  MaxSliderVal: number|string,
  sliderInvoiceValue:any,
  isCustomValue: any,
  daterangeOptionsData:string[],
  maxNumberSilder:any,
  statusValue:string,
  enableDateType:boolean,
  toDateVal:any,
  filterTotalValue:any,
  timeoutId:any,
  applyFilterData:boolean,

  //End of variables
  MinSliderInvoice:any,
  deleteReportDialoguebox: boolean,
  cancelDeleteReportDialogbox: boolean,
  anchorEl:null | (EventTarget & HTMLButtonElement),
  id: any,
  isLoading: any,
  page: any,
  rowsPerPage: any,
  projectItem: any,
  isNoDataAvailable: boolean;
  vendorChangeReportData: any;
  series: any;
  MaxSliderInvoice:any,

  discrepancyArray: any;
  totalUniqueVendor: any;
  vendorDilogueOpen: any;
  vendorSearchInputValue: any;
  reportSearchInputValue:any;
  filteredVendorList: any;
  project_name: any;
  project_id: any;
  loadingVendorData: any;
  highlightedProductsMap: any;
  vendorallDocValue: any;
  projectallDocValue: any;
  sortConfig:{
    direction:'ascending'|'descending',
    key:string
  }
  anchorimvoiceEl:HTMLDivElement | null;
  currentModelValue:[string, number][];
  reportItem:ReportItem
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

interface ChipData {
  label: string | number;
  key: number;
}

export default class ViewReportController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  filterApplyApiId: string = "";

  viewReportId: string = "";
  viewReportResultId:string="";
  getProjectsId:string="";
  updateProjectStatusId: string = "";
  discrepancyDataId: string = "";
  deleteReportDataId:string = "";
  getVendorAPisListid2: string="";

  getSearchAllDocProjectApiId:string="";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
        this.state = {
      // Customizable Area Start
      columnsProject: [
        {
          id: "1",
          label: "S.no",
          key:"idx"
        },
        { id: "2", label: "Report Name", key:'report_name' },
        { id: "3", label: "Date Created",key:'date_created' },
        { id: "4", label: "Quote Uploaded", key:"quote_uploaded" },
        { id: "5", label: "Invoice Uploaded",key:"invoice_uploaded" },
        { id: "6", label: "Last Modified",key:"last_modified" },
        { id: "7", label: "No. of Invoices",key:"no_of_inovices" },
        { id: "8", label: "Total Discrepancy",key:"total_discrepancy" },
        { id: "8", label: "Action",key:"" },
      ],
      reportList: [],
      vendorListData:[],
      //Filter State 
      openDialog: false,
      timeoutId:null,
      daterangeOptionsData: ['This week','Last week', 'Last month',"Custom"],
      StatusOptionsData: ['All', 'Active', 'Inactive'],
      vendorName:'',
      setAnchorEl: null,
      applyFilterData:true,
      //Here are value added
      dataChipArray:[],
      reportProjects: [],
      MinSliderVal: 0,
      MaxSliderVal: 0,
      sliderValue: [0,0],
      MinSliderInvoice:0,
      MaxSliderInvoice:100,
      sliderInvoiceValue:0,
      isCustomValue: "",
      minNumberSilder:0,
      maxNumberSilder:0,
      statusValue:'',
      dateVal:'',
      endDateDisabledVal:false,
      enableDateType:false,
      filterTotalValue:null,
      toDateVal:'',
      //here are value ended 
      deleteReportId: null,
      deleteReportDialoguebox:false,
      cancelDeleteReportDialogbox:false,
      anchorEl:null,
      id: '',
      isLoading: false,
      page: 0,
      rowsPerPage: 5,
      projectItem: {},
      isNoDataAvailable: true,
      vendorChangeReportData: {},
      series: [20, 50],
      discrepancyArray: [],
      totalUniqueVendor: 0,
      vendorDilogueOpen: false,
      vendorSearchInputValue: "",
      reportSearchInputValue:"",
      filteredVendorList: [],
      project_name: "",
      project_id: 0,
      loadingVendorData: false,
      highlightedProductsMap: new Map(),
      sortConfig:{
        direction:'ascending',
        key:'idx'
      },
      anchorimvoiceEl:null,
      vendorallDocValue: "",
      projectallDocValue: "",
      currentModelValue:[],
      reportItem:{
        report_id: 0,
        report_name: "",
        date_created: "",
        quote_uploaded: "",
        invoice_uploaded: "",
        last_modified: "",
        no_of_invoices: 0,
        status_invoices: [],
        total_discrepancy: 0,
        idx: 0,
        last_email_sent_at: ""
      }
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationScreenNameMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

 // Customizable Area Start

  getToken = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
  }

  async componentDidMount() {
    
    let specificReport = localStorage.getItem("projectSearch");
    let specificproject = localStorage.getItem("projectSearchName");
    if (specificReport) {
      this.setState({
        project_id: specificReport,
        project_name: specificproject
      }, () => {
        this.viewReport(this.state.project_id);
      });
    }
    else {
      const itemViewid = await getStorageData("reportFirstpage");
      const itemViewiddata = JSON.parse(itemViewid);
      this.viewReport(itemViewiddata.id)
      this.setState({
        project_name: itemViewiddata.project_name,
        project_id: itemViewiddata.id
      })
      this.handleDiscrepancyApiCall(0)
      this.vendorListHandler(itemViewiddata.id)
    }
  }

  handleVendorChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const selectedVendorValue = event.target.value as string;

    this.setState({ vendorName: selectedVendorValue })
  }

  vendorListHandler = (id:any) => {
    const head = {token: this.getToken()};

    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    
    
    this.getVendorAPisListid2 = requestMessage.messageId

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listVendorApiFilter}${id}`
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(head)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleChipData = () => {
    const { vendorName, dateVal, toDateVal, isCustomValue,sliderInvoiceValue,MinSliderVal,MaxSliderVal} = this.state
    let data:{label:string | number,key:number}[] = [];
    vendorName && data.push({label: `Vendors: ${vendorName}`,key:data.length})
    dateVal && data.push({label: `From: ${dateVal}`,key:data.length})
    toDateVal && data.push({label:`To: ${toDateVal}` ,key:data.length})
    isCustomValue && data.push({ label: `Date-Created: ${isCustomValue}`, key: data.length });
    sliderInvoiceValue && data.push({ label: `Invoice No. : ${sliderInvoiceValue}`, key: data.length })
    MinSliderVal && data.push({ label: `Min-Discrepancy: ${MinSliderVal}`, key: data.length })
    MaxSliderVal && data.push({ label: `Max-Discrepancy: ${MaxSliderVal}`, key: data.length })


    this.setState({dataChipArray:data})
  }

  handleClickOnChangePage = (event: unknown, newPage: number) => {
    this.setState({ 
      page: newPage 
    });
  };

  handleChangeOnPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ 
      page: 0, 
      rowsPerPage: parseInt(event?.target?.value, 10) 
    });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const msgData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      const id =  msgData.id;
      const project_name = msgData.projectName;

        this.viewReport(id)
        this.vendorListHandler(id)
        this.setState({ 
          project_name: project_name,
          project_id: id
        })
  
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('id', id);
        searchParams.set('project', project_name);
    
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.replaceState(null, "", newUrl);
        
        await setStorageData("reportFirstpage",JSON.stringify({id,project_name}))
    }
   

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
       
      if (apiRequestCallId === this.getVendorAPisListid2){
              this.handleVendorAPIResponse(responseJson)
      }

      if(apiRequestCallId === this.filterApplyApiId){  
        (responseJson?.filtered_project_dashboard_data?.length > 0) ?this.setState({reportList: responseJson.filtered_project_dashboard_data?.map((item: { idx: number; },idx:number)=>{item.idx=idx+1
              return item})}):this.setState({reportList: [].map((item: { idx: number; },idx:number)=>{item.idx=idx+1 
                return item})})
      }

      if (apiRequestCallId === this.viewReportId) {
                this.setState({ vendorChangeReportData: responseJson,
          loadingVendorData: false,
          reportList: responseJson.project_reports.map((item: { idx: number; },idx:number)=>{
          item.idx=idx+1
          return item
          }), isLoading: false, discrepancyArray: responseJson.discrepancy_by_vendor, filteredVendorList: responseJson.discrepancy_by_vendor, totalUniqueVendor: responseJson.total_unique_vendors
        });
        this.handleSeriesData(responseJson)
      }
      if (apiRequestCallId === this.updateProjectStatusId) {
        this.viewReport(this.state.project_id)
      }
      
      this.handleDeleteResponse(apiRequestCallId,responseJson)
      }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
       
      if (apiRequestCallId === this.viewReportResultId){
        this.setState({
          reportList: responseJson.project_reports?.map((item: { idx: number; },idx:number)=>{
            item.idx=idx+1
            return item
          })
        })
      }

      if (apiRequestCallId === this.viewReportId) {
        this.setState({ vendorChangeReportData: responseJson,loadingVendorData: false,reportList: responseJson.project_reports.map((item: { idx: number; },idx:number)=>{item.idx=idx+1
        return item
        }), isLoading: false, discrepancyArray: responseJson.discrepancy_by_vendor, filteredVendorList: responseJson.discrepancy_by_vendor, totalUniqueVendor: responseJson.total_unique_vendors })
        this.handleSeriesData(responseJson)
      }
      if (apiRequestCallId === this.updateProjectStatusId) {
        this.viewReport(this.state.project_id)
      }
      this.handleDeleteResponse(apiRequestCallId,responseJson)
    }
  }

  handleVendorAPIResponse(responseJson: any) {
    const allVendorName = "All"

    const vendorList = responseJson.vendors?.data?.map((vendorList: any) => {
      return vendorList?.vendor_name
    })

    this.setState({ vendorListData: vendorList }, () => {this.setState({ vendorListData: [allVendorName, ...this.state.vendorListData] })
    })
  }

  handleDeleteResponse = (apiRequestCallId:string, responseJson:string) =>{
    if(apiRequestCallId == this.deleteReportDataId){
      this.viewReport(this.state.project_id)
      this.setState({deleteReportDialoguebox:false})
    this.setState({deleteReportId:null})
    }
  }
  handleClickNew = (event: React.MouseEvent<HTMLElement>,id: number,item:ReportItem) => {
    event.stopPropagation();
    this.setState({setAnchorEl:event.currentTarget, reportItem: item})
  };

  handleCloseNew = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    this.setState({setAnchorEl: null})
  };

  handleSeriesData = (responseJson: any) => {
    if (responseJson.project_reports.length > 0) {
      this.setState({
        isNoDataAvailable: false
      })
    }
    if (responseJson.status === 'overcharged') {
        this.setState({ series: [Math.abs(Number(responseJson.status_percentage)), 0] })
    } else if (responseJson.status === 'undercharged') {
        this.setState({ series: [0, Number(responseJson.status_percentage)] })
    } else {
        this.setState({ series: [0, 0] })
    }
}


  handleDiscrepancyApiCall = (value: any) => {
    this.setState({ loadingVendorData: true })
    const header = {
        token: this.getToken()
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.discrepancyDataId = requestMessage.messageId

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.discrepancyApiEndPoint}?id=${value === 0 ? "all" : value}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
};


formatterFn = () => `$ ${this.state.vendorChangeReportData.total_dash_discrepancy}`

formattedNumber = (number: any) => {
  const result = Number(parseFloat(number).toFixed(0));
  const formatNumber = new Intl.NumberFormat('en-IN', {
      maximumFractionDigits: 2,
  }).format(result);
  return formatNumber
}

  viewReport = (id : any) => {
    this.setState({ isLoading: true })
    const header = {
      token: this.getToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewReportId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.viewReportApi}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Filter Function Pop UP
  handleClose = () => {
    this.setState({ 
      openDialog: false,
      vendorName: "",
      dateVal: "",
      toDateVal: "",
      isCustomValue: "",
      MinSliderVal: '',
      MaxSliderVal: '',
      endDateDisabledVal:false,
      filterTotalValue:null,
      sliderInvoiceValue:0
    });
  };


  handleOpen = () => {
    this.setState({ 
      openDialog: true 
    });
  };

  
  getProjects = () => {
    this.setState({ isLoading: true })
    const header = {
      token: this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectsId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  handleFromDateChange = (selectDate: Date | null) => {
    const selectedDateOne = selectDate ? moment(selectDate).format("MM-DD-YYYY") : '';

    this.setState(prevState => {
        const { toDateVal } = prevState; 
        let updatedToDateVal = toDateVal;

        if (selectedDateOne) {
            const fromDate = moment(selectDate);
            const toDate = moment(toDateVal, "MM-DD-YYYY");

            if (fromDate.isAfter(toDate)) {
                updatedToDateVal = selectedDateOne;
            }
        }

        return {
            dateVal: selectedDateOne,
            endDateDisabledVal: false,
            toDateVal: updatedToDateVal 
        };
    });
};

// For Discrepancy Slider
  handleSliderChange = (e: React.ChangeEvent<{}>, newValue:any) => {
    this.setState({ sliderValue: newValue,MinSliderVal:newValue[0],MaxSliderVal:newValue[1]})
  }

  handleMinSliderChange = (value: number) => {
    const selectedMinValue = value;
    this.setState({
      MinSliderVal: selectedMinValue,
      sliderValue: [selectedMinValue, this.state.sliderValue[1]]
    })

  }

  handleMaxSliderChange = (values: number) => {
    const selectedMaxValue = values;
    this.setState({
      MaxSliderVal: selectedMaxValue,
      sliderValue: [this.state.sliderValue[0], selectedMaxValue]
    })
  }


  // For Invoice Slider

  handleInvoiceSliderChange = (e: React.ChangeEvent<{}>, newValue:any ) => {
      this.setState({ sliderInvoiceValue: newValue });
  }

  
  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    this.setState({
      sliderInvoiceValue: checked ? 1: 0,
    });
  };


  // Here is the end of the slider inVoice

  handleDateChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const selectedVal = event.target.value
    this.setState({ isCustomValue: selectedVal }, () => {
      (selectedVal !== this.state.isCustomValue) ? this.setState({ enableDateType: false }) : this.setState({ enableDateType: true })
    })
  }
  
  formateData = (gaveValue2:string) =>{return gaveValue2 ? moment(gaveValue2).format("YYYY-MM-DD") : '';};

  timePerioddata =(value:any)=>{
    if (value=== "This week") {
      return "this_week";
    } else if (value === "Last week") {
       return "last_week";
    } else if (value=== "Last month") {
      return  "last_months";
    } else if(value === "Custom"){
      return "custom";
    }
    else {
      return "";
    }
  }

  AdjustedToData =(valueData:string,filterNumber:any)=>{
    if(valueData === 'custom'){
      return filterNumber-1
    }
    else{
      return filterNumber
    }
         
  }


  filterApplyHandler = () => {
    this.setState({
      openDialog:false
    })

    const { vendorName,dateVal, toDateVal, MinSliderVal, MaxSliderVal, isCustomValue,project_id,sliderInvoiceValue } = this.state;
  
  const tottalValue = [vendorName, dateVal, toDateVal, isCustomValue];
  const filterNumber = tottalValue.filter(value => value.length > 0).length;
  const adjustedTotalValue = this.AdjustedToData(isCustomValue,filterNumber);
  this.setState({filterTotalValue:adjustedTotalValue})
  
    let timePeriod = this.timePerioddata(this.state.isCustomValue);
    
    this.handleChipData()


    const header = {
      "token": this.getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterApplyApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.filterReportsFilter}?vendor_name=${vendorName}&start_date=${this.formateData(dateVal)}&end_date=${this.formateData(toDateVal)}&invoice_number=${sliderInvoiceValue}&min_total_discrepancy=${MinSliderVal}&max_total_discrepancy=${MaxSliderVal}&time_period=${timePeriod}&id=${project_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);


    return true;

  }

  resetAllHandler = () => {
    this.setState({
      endDateDisabledVal:false,
      filterTotalValue:null,
      dateVal: "",
      vendorName: "",
      isCustomValue: "",
      MinSliderVal: '',
      toDateVal: "",
      MaxSliderVal: '',
      openDialog: false,
      sliderInvoiceValue:0
    }, () => {
      this.viewReport(this.state.project_id);
      this.handleChipData();
    })

  }

  handleToDateChange = (tovalue: Date | null) => {
    const currentToDate = tovalue ? moment(tovalue).format("MM-DD-YYYY") : '';

    this.setState(prevState => {
        const { dateVal } = prevState; 
        let updatedFromDateVal = dateVal;

        if (currentToDate) {
            const toDate = moment(tovalue);
            const fromDate = moment(dateVal, "MM-DD-YYYY");
            if (toDate.isBefore(fromDate)) {   updatedFromDateVal = currentToDate; }
        }

        return {toDateVal: currentToDate,dateVal: updatedFromDateVal };
    });
};
 
 truncateTextList=(text:string)=> {
  return text.length > 30 ? text.slice(0, 30) + '...' : text;
}
  //Filter Functions End Here


  handleCheckFileUpload = (isUploaded : any) => {
    if(isUploaded === "Yes"){
      return "green"
    } else {
      return "red"
    }
  }
  
  handleDelete = (data: ChipData) => {


    this.setState(prevState => {
        const updatedDataChipArray = prevState.dataChipArray.filter(chip => chip.key !== data.key);
         let updatedState: any = {
            dataChipArray: updatedDataChipArray
            
        };

        if (typeof data.label === 'string' && data.label.startsWith("From:")) {
          updatedState.dateVal = "";
        }
        if (typeof data.label === 'string' && data.label.startsWith("To:")) {
          updatedState.toDateVal = "";
        }
        if (typeof data.label === 'string' && data.label.startsWith("Vendors:")) {
          updatedState.vendorName = "";
         }
        if (typeof data.label === 'string' && data.label.startsWith("Status:")) {
            updatedState.statusValue = "";
        }
        if (typeof data.label === 'string' && data.label.startsWith("Date-Created:")) {
            updatedState.isCustomValue = "";
        }
        if (typeof data.label === 'string' && data.label.startsWith("Invoice No")) {
          updatedState.sliderInvoiceValue = 0;
        }
        if (typeof data.label === 'string' && data.label.startsWith("Max-Discrepancy:")) {
          updatedState.MaxSliderVal = "";
      }
      if (typeof data.label === 'string' && data.label.startsWith("Min-Discrepancy:")) {
        updatedState.MinSliderVal = 0;
      }
        
        return updatedState;
    }, () => {
      this.checkAndUpdateCustomValue();
       this.filterApplyHandler();
       });

}

checkAndUpdateCustomValue = () => {
  if (!this.state.dateVal && !this.state.toDateVal) {
      this.setState({
          isCustomValue: ""
      });
  }
}

  handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>,  id: number,item:ReportItem) => {
    event?.stopPropagation();
    this.setState({anchorEl: event?.currentTarget, deleteReportId:id,reportItem:item}) 
  };

  handleClickCloseMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleCancelButton = (event: React.MouseEvent<HTMLButtonElement>) =>{
    event.stopPropagation()
    this.setState({deleteReportDialoguebox:false})
  }

  handleDeleteDialogBox = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({anchorEl:null, deleteReportDialoguebox:true,})
  }

  handleDeleteReport = async () => {
    const header = {
      token: this.getToken()
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.deleteReportDataId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteReportAPIEndPoint}${this.state.deleteReportId}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  
  handleDateFormat = (date: any) =>{
    if(date){
      return moment.utc(date).format('MM-DD-YYYY, hh:mm A')
    } else{
      return "-"
    }
  } 

  handleCloseVendor = () => {
    this.setState({ 
      vendorDilogueOpen: false
    });
  };

  handleOpenVendor = () => {
    this.setState({ 
      vendorDilogueOpen: true 
    });
  };

  handleSearchVendor = (event: any) => {
   this.setState({
    vendorSearchInputValue: event?.target?.value
   }, () => {
    const filteredArray = this.state.discrepancyArray?.filter((item: any) =>
        item.vendor.toLowerCase().includes(this.state.vendorSearchInputValue?.toLowerCase()) ||  item.total_discrepancy.toString().includes(this.state.vendorSearchInputValue?.toLowerCase())
    );
    this.setState({ filteredVendorList: filteredArray })
   })
  };


  handleCustomFieldValue =(vendorName:any,sliderInvoiceValue:any,MaxSliderVal:any,MinSliderVal:any,isCustomValue:any,toDateVal:any,dateVal:any)=>{
    if(isCustomValue === "Custom"){
       return (toDateVal || dateVal)
      }
    else{
       return (vendorName || sliderInvoiceValue || MaxSliderVal || MinSliderVal || isCustomValue)
      }
}


  handleSearchReport = (event: { target: { value: any; }; }) => {
    const newValue = event.target.value;
    this.setState({ reportSearchInputValue: newValue });
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }


    if (newValue.length === 0) {
      this.viewReport(this.state.project_id);
    } else {
       const timeoutId = setTimeout(() => {
        this.searchReportApi(newValue.trim());
      }, 1000); 

      this.setState({ timeoutId });
    }

  };

  searchReportApi = (query: any) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewReportResultId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_projects/projects/filter_report_data?search=${query}&id=${this.state.project_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  navigateToViewReport = (id : any, reportName: string) =>{
    const data = { id: this.state.reportItem.report_id, reportName: this.state.reportItem.report_name, project: this.state.project_name}
     setStorageData("dataViewreport",JSON.stringify(data))

    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), "ViewReportDetails");
    msgs.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);
    msgs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgs)
  }

  navigateToDiscrepancy = () =>{
    const dataItem = { id: this.state.reportItem.report_id, reportName: this.state.reportItem.report_name, project: this.state.project_name}
     setStorageData("dataViewreport",JSON.stringify(dataItem))
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), "viewDiscrepancySummary");
    msgs.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), dataItem);
    msgs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgs)
  }

  sortTableColumn=()=>{
    return this.state.reportList.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => {
      if(this.state.sortConfig.key === "total_discrepancy"){
        const bValue = b[this.state.sortConfig.key];
        const aValue = a[this.state.sortConfig.key];

        const bNumericData = parseFloat(String(bValue).replace(/,/g, ''));

        const aNumericData = parseFloat(String(aValue).replace(/,/g, '')) ;
  
        if (this.state.sortConfig.direction === "ascending")  return aNumericData - bNumericData;
        if (this.state.sortConfig.direction === "descending") return  bNumericData - aNumericData;
      }
      if (a[this.state.sortConfig.key] < b[this.state.sortConfig.key]) return this.state.sortConfig.direction === 'ascending' ? -1 : 1; 
      if (a[this.state.sortConfig.key] > b[this.state.sortConfig.key]) return this.state.sortConfig.direction === 'ascending' && 1 ;     
    });
  }

  sortClick = (keys: string) => {
    this.setState((prevState) => {
      let direction:"descending" | "ascending"='descending'
      if(prevState.sortConfig.key === keys){
        if(prevState.sortConfig.direction === 'descending'){
          direction='ascending'
        }
      }
      return({
      sortConfig: {
        key: keys,
        direction
      }
    })
  })
  }

  handleinvoicenoPopoverOpen = (event:React.MouseEvent<HTMLDivElement>,getitemPay:[string, number][]) => {
    this.setState({ anchorimvoiceEl: event.currentTarget ,currentModelValue:getitemPay});
  };

  handleinvoicePopoverClose = () => {
    this.setState({ anchorimvoiceEl: null});
  };

  vaitingForapproval = (waitValue:string) =>{
     return waitValue === "true" ? 'Approved to pay' : 'Awaiting approval';
  };

  vaitingColorForapproval = ( colorval:string) =>{
    return colorval === "true" ? '#3FC659':'#87888F'
  };

  invoiceNocolor = (colorNo:{no_of_inovices:number}) =>{
  return colorNo.no_of_inovices == 0 ? '#05052B': '#DF802D';
  };

  invoiceEvent = (eventNo:{no_of_inovices:number}) =>{
  return eventNo.no_of_inovices == 0 ? 'none': 'auto';
  };
  getCsvData = () => {
    const { columnsProject, reportList } = this.state;
    const csvData = [
      [...columnsProject
        .filter((heading) => heading.label !== 'Action')
        .map(heading => heading.label)],

      ...reportList.map((item: { [item: string]: string; }, fileIndex: number) => {
        return [
          fileIndex + 1,
          ...columnsProject.map(heading => {
            let value = "";

            const keyItem = keyMappings[heading.label] || '';
            value = item[keyItem];

            if (value === undefined) {
              const adjustedKey = heading.label.split(" ").join("_").toLowerCase();
              value = item[adjustedKey];
            }

            return value !== undefined ? value : null;
          }).filter(value => value !== null)
        ];
      })
    ];
    return csvData;
  }

  // Customizable Area End
}
