// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  classes: any;
  navigation: any;
  handleSaveData: (data: any) => void;
  history: any
  // Customizable Area End
}

export interface TablebodyCellProps {
  index: number,
  classes: any,
  project_name: string,
  created_at: string,
  status: string,
  report_created: number,
  reports_without_invoice: number,
  total_discrepancy: number,
  last_modified: string,
  handleTimeZoneData: (event: string | null) => string;
}

export interface FilterDialogProps {
  classes: any,
  daterangeOptionsData: string[],
  StatusOptionsData: string[],
  vendorListData: string[]
  handleCloseFilter: () => void,
  sliderInvoiceValueData:any,
  handleCustomValue:any,
  handleOpen: boolean,
  vendorName: string,
  handleSliderChange: (event: React.ChangeEvent<{}>, newValue: number | number[]) => void,
  sliderValue: number[],
  isCustomValue: string,
  statusValue: string,
  MaxSliderVal: string | number,
  MinSliderVal: string | number,
  dateVal: string,
  toDateVal: string,
  endDateDisabledVal: boolean,
  handleInvoiceSliderChange:(event: React.ChangeEvent<{}>, value: number | number[]) => void,
  truncateText:(value:string) =>  string ,
  handleDateChange: (event: React.ChangeEvent<{ value: string | unknown }>) => void
  handleVendorChange: (event: React.ChangeEvent<{ value: string | unknown }>) => void
  handleStatusChange: (event: React.ChangeEvent<{ value: string | unknown }>) => void
  handleMinSliderChange: (values: any) => void
  handleMaxSliderChange: (values: any) => void
  filterApplyHandler: () => void
  handleFromDateChangeTo: (event: Date | null) => void
  handleToDateChangeTo: (event: Date | null) => void
  resetAllHandler: () => void
  minNumberSilder: number  
  maxNumberSilder: number 
}


interface Project {
  idx:number
  project_id: number,
  project_name: string;
  created_at: string;
  status: string;
  reports_created: number;
  report_without_invoice: number;
  last_modified: string;
  total_discrepancy: number;
}
interface ChipData {
  label: string | number;
  key: number;
}
interface VendorResponse {
  project_dashboard: Project[];
}
interface FilteredDataResponse {
  message: "",
  filtered_project_dashboard_data: Project[];
}

interface VendorResponse {
  project_dashboard: Project[];
}


export interface columnProject {
  id: string;
  label: string;
  key:string;
}

interface CustomChangeEvent {
  target: {
    value: number;
  };
}




interface S {
  // Customizable Area Start
  filterTotalValue:null | number,
  daterangeOptionsData: string[],
  StatusOptionsData: string[]
  reportProjects: any,
  // personName: string,
  statusValue: string,
  vendorName: string,
  columnsProject: columnProject[],
  dateVal: string,
  toDateVal: string,
  endDateDisabledVal: boolean,
  enableDateType: boolean,
  openDialog: boolean,
  dataChipArray: {label:string | number,key:number}[],
  sliderValue: number[],
  isCustomValue: any,
  addNameErrMsg: any,
  inputName: any,
  openAddNameDialog: any,
  anchorEl: any,
  id: any,
  isLoading: any,
  page: any,
  reportIDValue:any,
  rowsPerPage: any,
  projectItem: any,
  MinSliderVal: string|number,
  MaxSliderVal: string|number,
  vendorListData: string[],
  uploadInvoiceOpenModel: boolean,
  ReportValue: any;
  projectId: number;
  reportListRender:boolean;
  projectValue: any;
  projectDropDown: any;
  searchProjectQuery: any;
  reportDropDown: any;
  projectFilteredData: any;
  searchReportQuery: any;
  reportFilteredData: any;
  sliderInvoiceValueData:any;
  ReportDataFlag: boolean;
  uploadInvoiceProjectDataFlag: boolean;
  projectEmptyValue: boolean;
  reportEmptyValue: boolean;
  isAsc: boolean;
  minNumberSilder:number;
  maxNumberSilder:number;
  projectSearchValue: string;
  timeoutId: NodeJS.Timeout | number | null;
  projectDetailsData: string;
  isPageActive : boolean;
  sortConfig:{
    direction:'ascending'|'descending',
    key:string
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addNameProjectId: string = "";
  getProjectsId: string = "";
  updateProjectStatusId: string = "";
  filterApplyApiId: string = "";
  getVendorsAPIId: string = "";
  getProjectDataSelectId: string = "";
  getReportDataSelectId: string = "";
  projectSearchApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.state = {
      // Customizable Area Start
      filterTotalValue:null,
      daterangeOptionsData: ['This week', 'Last week', 'Last month', 'Custom'],
      vendorListData: [],
      StatusOptionsData: ['All', 'Active', 'Inactive'],
      dateVal: "",
      toDateVal: '',
      endDateDisabledVal: false,
      enableDateType: false,
      vendorName: "",
      statusValue: "",
      columnsProject: [
        {
          id: "1",
          label: "S.no",
          key:"idx"
        },
        { id: "2", label: "Project Name", key:'project_name' },
        { id: "3", label: "Date Created",key:'created_at' },
        { id: "4", label: "Status", key:"status" },
        { id: "5", label: "Reports Created",key:"report_created" },
        { id: "6", label: "Report without invoice",key:"reports_without_invoice" },
        { id: "7", label: "Last Modified",key:"last_modified" },
        { id: "8", label: "Total Discrepancy",key:"total_discrepancy" },
        { id: "9", label: "Action",key:"" },
      ],
      dataChipArray:[],
      reportListRender:false,
      reportProjects: [],
      reportIDValue:'',
      openDialog: false,
      MinSliderVal: '',
      MaxSliderVal: '',
      sliderValue: [],
      isCustomValue: "",
      sliderInvoiceValueData:0,
      addNameErrMsg: "",
      inputName: "",
      openAddNameDialog: false,
      anchorEl: null,
      id: '',
      isLoading: false,
      page: 0,
      rowsPerPage: 5,
      projectItem: {},
      uploadInvoiceOpenModel: false,
      reportDropDown: [],
      projectDropDown: [],
      projectValue: '',
      ReportValue: '',
      uploadInvoiceProjectDataFlag: false,
      searchProjectQuery: '',
      searchReportQuery: '',
      projectFilteredData: [],
      reportFilteredData: [],
      projectId: 0,
      ReportDataFlag: false,
      projectEmptyValue: false,
      reportEmptyValue: false,
      isAsc: true,
      minNumberSilder:0,
      maxNumberSilder:0,
      projectSearchValue: "",
      timeoutId: null,
      projectDetailsData: "",
      isPageActive : false,
      sortConfig:{
        direction:'ascending',
        key:'idx'
      },
      // Customizable Area End
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationScreenNameMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    return document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
  }

  sortTableColumnDataValue=()=>{
    return this.state.reportProjects.sort((a: { [x: string]: any ; }, b: { [x: string]: any; }) => {
      if(this.state.sortConfig.key === "total_discrepancy"){
        const aValueData = a[this.state.sortConfig.key];
        const bValueData = b[this.state.sortConfig.key];
    
        const aNumericValue = parseFloat(String(aValueData).replace(/,/g, '')) ;
        const bNumericValue = parseFloat(String(bValueData).replace(/,/g, '')) ;
  
        if (this.state.sortConfig.direction === "ascending")  return aNumericValue - bNumericValue;
        
        if (this.state.sortConfig.direction === "descending") return  bNumericValue - aNumericValue;
      }
      if (a[this.state.sortConfig.key] > b[this.state.sortConfig.key]) return this.state.sortConfig.direction === 'ascending' && 1 ;

      if (a[this.state.sortConfig.key] < b[this.state.sortConfig.key]) return this.state.sortConfig.direction === 'ascending' ? -1 : 1;      
    });
  }
 
  sortClick = (keys: string) => {
    this.setState((prevState) => {
      let direction:"descending" | "ascending"='descending'
      if(prevState.sortConfig.key === keys){
        if(prevState.sortConfig.direction === 'descending'){
          direction='ascending'
        }
      }
      return({
      sortConfig: {
        key: keys,
        direction
      }
    })
  })
  }

  

  handleOpenDialog = (event: any) => {
    event?.stopPropagation();
    this.setState({ openAddNameDialog: true })
  }

  async componentDidMount() {
    let specificProject =  localStorage.getItem("projectSearch");
    if(specificProject){
     this.projectSearchApiFunction(specificProject);
   }
    this.vendorListHandler()
    if(!this.state.isPageActive){
      setTimeout(()=>{
        if(!this.state.isPageActive){
          this.getProjects()
        }
      },1000)
    }
    this.setState({
      sliderValue: [Number(this.state.MinSliderVal), Number(this.state.MaxSliderVal)]
    });
    this.projectSelectData()
    this.reportSelectData()
  }

  handleInvoiceSliderChange = (e: React.ChangeEvent<{}>, newValue:any ) => {
    this.setState({ sliderInvoiceValueData: newValue });
}

truncateText=(text:string)=> {
  return text.length > 30 ? text.slice(0, 30) + '...' : text;
}


handleTimeZoneData =(created_at:string | null)=>{
  if(created_at) { 
    return moment.utc(created_at).format('MM-DD-YYYY, hh:mm A')
  }
  else{
    return "--"
  } 
}

  handleDelete = (data: ChipData) => {
    
    this.setState(prevState => {
      const updatedDataChipArray = prevState.dataChipArray.filter(chip => chip.key !== data.key);
      let updatedState: any = {
            dataChipArray: updatedDataChipArray
            
        };

        if (typeof data?.label === 'string' && data?.label.startsWith("From:")) {
          updatedState.dateVal = "";
        }
        if (typeof data?.label === 'string' && data?.label.startsWith("To:")) {
          updatedState.toDateVal = "";
        }
        if (typeof data?.label === 'string' && data?.label.startsWith("Vendors:")) {
          updatedState.vendorName = "";
         }
        if (typeof data?.label === 'string' && data?.label.startsWith("Status:")) {
            updatedState.statusValue = "";
        }
        if (typeof data?.label === 'string' && data?.label.startsWith("Date-Created:")) {
            updatedState.isCustomValue = "";
        }
        if (typeof data?.label === 'string' && data?.label.startsWith("No-Reports:")) {
          updatedState.sliderInvoiceValueData = "";
       }
       if (typeof data?.label === 'string' && data?.label.startsWith("Max-Discrepancy:")) {
        updatedState.MaxSliderVal = "";
       }
       if (typeof data?.label === 'string' && data?.label.startsWith("Min-Discrepancy:")) {
        updatedState.MinSliderVal = "";
      }
        return updatedState;
    }, () => {
      this.checkAndUpdateCustomValue();
       this.filterApplyHandler();
       });
}

checkAndUpdateCustomValue = () => {
  if (!this.state.dateVal && !this.state.toDateVal) {
      this.setState({
          isCustomValue: ""
      });
  }
}

  handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>, index: string, projectItem: string) => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget, id: index, projectItem });
  };

  handleClickCloseMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({ anchorEl: null });
  };

  handleClickChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage });
  };

  handleChangePerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event?.target?.value, 10) });
  };

  handleDeactivateStatus = (id:any) => {
    this.setState({ anchorEl: null });
    const header = {
      token: this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateProjectStatusId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateProjectStatusEndPoint}?project_id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editQuoteDetailAPIMethodPATCH
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getProjectsId) {   
        const discrepancies = responseJson.project_dashboard.map((item:{total_discrepancy:number}) => item.total_discrepancy);
        const mostNegativeDiscrepancy = Math.min(...discrepancies);  
        const mostPositiveDiscrepancy = Math.max(...discrepancies);
        this.setState({
          minNumberSilder:mostNegativeDiscrepancy,maxNumberSilder:mostPositiveDiscrepancy
        });
        this.setState({ reportProjects: responseJson.project_dashboard.map((item: { idx: number; },idx:number)=>{
          item.idx=idx+1
          return item
        }), isLoading: false },
        )
      }
      if (apiRequestCallId === this.updateProjectStatusId) {
        this.getProjects()
      }
      if (apiRequestCallId === this.filterApplyApiId) {
        this.handleVendorFilterResponse(responseJson)
      }
      if (apiRequestCallId === this.getVendorsAPIId) {
        this.handleVendorAPIResponse(responseJson)
      }
      if (apiRequestCallId === this.getProjectDataSelectId) {
        this.receiveProjectData(responseJson)
      }
      if (apiRequestCallId === this.getReportDataSelectId) {
        this.receiveReportData(responseJson)
      }
      if(apiRequestCallId === this.projectSearchApiCallId) {
        this.setState({
          reportProjects: responseJson.project_dashboard.map((projectItem: { idx: number }, idx: number) => {
            projectItem.idx = idx + 1;
            return projectItem;
          }), isLoading: false, page :0
        }, () => {
          localStorage.removeItem("projectSearch");
        });
      }
      this.handleAddProjectResponse(apiRequestCallId, responseJson)

    }
  }

  handleVendorFilterResponse = (responseJson: FilteredDataResponse) => {
    if (!responseJson.message) {
      this.setState({
        reportProjects: responseJson.filtered_project_dashboard_data,
        openDialog: false,
      })
       this.setState({ reportProjects: responseJson.filtered_project_dashboard_data.map((item,idxno)=>{
          item.idx=idxno+1
          return item
        }), isLoading: false },
        )
    } else {
      this.setState({
        reportProjects: [],
        openDialog: false,
      })
    }
  }

  handleVendorAPIResponse = (responseJson: VendorResponse) => {
    const allVendorName = "All"

    const vendorList = responseJson.project_dashboard.map((vendorList: any) => {
      return vendorList.project_name
    })
    this.setState({ vendorListData: vendorList }, () => {
      this.setState({ vendorListData: [allVendorName, ...this.state.vendorListData] })
    })
  }

  handleAddProjectResponse = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.addNameProjectId) {
      if (responseJson.errors) {
        if(responseJson.errors[0].project){
        this.setState({ addNameErrMsg: responseJson.errors[0].project })
        }else{
        this.setState({ addNameErrMsg: responseJson.errors })
        }
      } else {
        this.setState({ openAddNameDialog: false, inputName: '' });
        this.getProjects()
      }
    }
  }

  getProjects = () => {
    this.setState({ isLoading: true })
    const header = {
      token: this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectsId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleInputNameChange = (event: any) => {
    const { target: { value } } = event;
    this.setState({ inputName: value, addNameErrMsg: '' })
  }

  handleAddProjectName = () => {
    const header = {
      token: this.getToken()
    };

    const formData: any = new FormData();

    formData.append('project', this.state.inputName);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addNameProjectId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addProjectNameEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleVendorChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {

    const selectedVendorValue = event.target.value as string;
    this.setState({ vendorName: selectedVendorValue })
  }

  handleStatusChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const selectedStatusValue = event.target.value as string;
    this.setState({ statusValue: selectedStatusValue })
  }

  handleFromDateChangeTo = (selectDate: Date | null) => {
    const selectedDateOne = selectDate ? moment(selectDate).format("MM-DD-YYYY") : '';
    this.setState(prevState => {
        const { toDateVal } = prevState; 
        let updatedToDateVal = toDateVal;
        if (selectedDateOne) {
            const fromDate = moment(selectDate);
            const toDate = moment(toDateVal, "MM-DD-YYYY");
             if (fromDate.isAfter(toDate)) {
                updatedToDateVal = selectedDateOne;
            }}
        return {dateVal: selectedDateOne,endDateDisabledVal: false,toDateVal: updatedToDateVal };});
};


handleToDateChangeTo = (tovalue: Date | null) => {
  const currentToDate = tovalue ? moment(tovalue).format("MM-DD-YYYY") : '';
  this.setState(prevState => {
      const { dateVal } = prevState; 
      let updatedFromDateValue = dateVal;
      if (currentToDate) {

          const toDate1 = moment(tovalue);
          
          const fromDate2= moment(dateVal, "MM-DD-YYYY");
          if (toDate1.isBefore(fromDate2)) {
            updatedFromDateValue = currentToDate; 
          }}
      return { toDateVal: currentToDate,  dateVal: updatedFromDateValue};
     });
};

  handleCloseFilter = () => {
    this.setState({ 
      endDateDisabledVal:false, 
      filterTotalValue:null,
      sliderInvoiceValueData:0,
      vendorName: "",
      statusValue: "",
      dateVal: "",
      openDialog: false,
      MinSliderVal: '',
      toDateVal: "",
      isCustomValue: "",
      MaxSliderVal: '',
      uploadInvoiceOpenModel: false,
      projectValue: "",
      ReportValue: "",
      projectEmptyValue: false, 
      reportEmptyValue: false
    });
  };

  handleAddNameDialogClose = () => {
    this.setState({ openAddNameDialog: false });
  };

  handleOpen = () => {
    this.setState({ openDialog: true });
  };



  handleSliderChange = (e: React.ChangeEvent<{}>, newValue: number | number[]) => {
    let sliderNewValue: number[] = [];
    if (typeof newValue === 'number') {
      sliderNewValue = [newValue];
    } else {
      sliderNewValue = newValue;
    }
    this.setState({ sliderValue: sliderNewValue, MinSliderVal: sliderNewValue[0], MaxSliderVal: sliderNewValue[1] })
  }

  handleMinSliderChange = (value: number) => {
    const selectedMinValue = value;
    this.setState({
      MinSliderVal: selectedMinValue,
      sliderValue: [selectedMinValue, this.state.sliderValue[1]]
    })
  }

  handleMaxSliderChange = (values: number) => {
    const selectedMaxValue = values;
    this.setState({
      MaxSliderVal: selectedMaxValue,
      sliderValue: [this.state.sliderValue[0], selectedMaxValue]
    })
  }

  handleDateChange = (event: React.ChangeEvent<{ value: string | unknown }>) => {
    const selectedVal = event.target.value
    this.setState({ isCustomValue: selectedVal }, () => {
      if (selectedVal !== this.state.isCustomValue) {
        this.setState({ enableDateType: false })
      } else {
        this.setState({ enableDateType: true })
      }
    })

  }
  
  vendorListHandler = () => {
    const header = {
      token: this.getToken()
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getVendorsAPIId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProjectsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleChipData = () => {
    const { vendorName, statusValue, dateVal, toDateVal, MinSliderVal, MaxSliderVal, isCustomValue,sliderInvoiceValueData} = this.state
    let data:{label:string | number,key:number}[] = [];
    vendorName && data.push({label: `Vendors: ${vendorName}`,key:data.length})
    statusValue && data.push({label: `Status: ${statusValue}`,key:data.length})
    dateVal && data.push({label: `From: ${dateVal}`,key:data.length})
    toDateVal && data.push({label:`To: ${toDateVal}` ,key:data.length})
    sliderInvoiceValueData && data.push({label:`No-Reports: ${sliderInvoiceValueData}`,key:data.length})
    MaxSliderVal && data.push({label:`Max-Discrepancy: ${MaxSliderVal}`,key:data.length})
    MinSliderVal && data.push({label:`Min-Discrepancy: ${MinSliderVal}`,key:data.length})


    if (isCustomValue && isCustomValue !== 'Custom') {
      data.push({ label: `Date-Created: ${isCustomValue}`, key: data.length });
    }

    this.setState({dataChipArray:data})

  }

  formateData = (getvalue:string) =>{
   return getvalue ? moment(getvalue).format("YYYY-MM-DD") : '';
  };
  
  filterApplyHandler = () => {

  const { vendorName, statusValue, dateVal, toDateVal, MinSliderVal, MaxSliderVal, isCustomValue ,sliderInvoiceValueData} = this.state

  const tottalValue = [vendorName, statusValue, dateVal, toDateVal, isCustomValue,sliderInvoiceValueData,MaxSliderVal,MinSliderVal];
  
  const filterNumber = tottalValue.filter(value => value.length > 0).length;
  const adjustedTotalValue = isCustomValue === "Custom" ? filterNumber -1 : filterNumber;
  this.setState({filterTotalValue:adjustedTotalValue})
  
    const statusCheck = statusValue === 'Active' || statusValue === 'Inactive';
    let timePeriod = "";
    if (isCustomValue === "This week") {
      timePeriod = "this_week";
    } else if (isCustomValue === "Last week") {
      timePeriod = "last_week";
    } else if (isCustomValue === "Last month") {
      timePeriod = "last_month";
    } else {
      timePeriod = "custom";
    }

    this.handleChipData();

    const formData: any = new FormData();
    formData.append("project_name", vendorName);
    statusCheck && formData.append("status", statusValue.toLocaleLowerCase());
    formData.append("start_date",  this.formateData(dateVal));
    formData.append("end_date",  this.formateData(toDateVal));
    formData.append("min_total_discrepancy", MinSliderVal);
    formData.append("max_total_discrepancy", MaxSliderVal);
    formData.append("time_period", timePeriod);
    formData.append("no_of_reports",sliderInvoiceValueData);

    const header = {
      "token": this.getToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.filterApplyApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.filterVendorsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;

  }

  resetAllHandler = () => {
    this.setState({
      openDialog: false,
      endDateDisabledVal:false,
      filterTotalValue:null,
      sliderInvoiceValueData:0,
      vendorName: "",
      statusValue: "",
      dateVal: "",
      toDateVal: "",
      isCustomValue: "",
      MinSliderVal: '',
      MaxSliderVal: '',
    }, () => {
      this.getProjects()
      this.handleChipData();
    })

  }

  handleCustomValue =(vendorName:any,sliderInvoiceValueData:any, MaxSliderVal:any,MinSliderVal:any , statusValue:any ,isCustomValue:any,toDateVal:any,dateVal:any)=>{
      if(isCustomValue === "Custom"){
        return (toDateVal || dateVal)
      }

      else{
        return (vendorName || sliderInvoiceValueData || MaxSliderVal || MinSliderVal || statusValue || isCustomValue)
      }

  }

  navigateToViewReport = async(id: number, projectName: string) => {
    const data = { id: id, projectName: projectName }
    await setStorageData("reportof",JSON.stringify(data))
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), `Viewreport`);
    msgs.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), data);
    msgs.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msgs)
  }


  handleOpenModel = () => {
    this.setState({ uploadInvoiceOpenModel: true,reportListRender:false })
  }

  handleCloseModel = () => {
    this.setState({ uploadInvoiceOpenModel: false, uploadInvoiceProjectDataFlag: false, ReportDataFlag: false, projectValue: "", ReportValue: "", searchReportQuery: "", searchProjectQuery: "", projectEmptyValue: false, reportEmptyValue: false,reportListRender:false});
  };

  projectSelectData = () => {
    const header = {
      "Content-Type": configJSON.callApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };

    const projectComparisonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProjectDataSelectId = projectComparisonMessage.messageId;

    projectComparisonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.uploadInvoiceprojectDataSelected
    );

    projectComparisonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    projectComparisonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(projectComparisonMessage.id, projectComparisonMessage);
    return true;
  }

  receiveProjectData = (responseJson: any) => {
    if (responseJson) {
      const projectData = responseJson.reports.map((item: any) => {
        return {
          id: item[0],
          value: item[1],
        };
      });
      this.setState({ projectFilteredData: projectData, projectDropDown: projectData })
    }
  }

  handleProjectSearch = (event: any) => {
    const query = event.target.value;
    this.setState({ searchProjectQuery: query }, () => {
      const projectFilteredData = this.state.projectDropDown.filter((item: { value: string; }) =>
        item.value.toLowerCase().includes(query.toLowerCase())
      );
      this.setState({ projectFilteredData });
    });
  };

  reportSelectData = () => {
    const header = {
      "Content-Type": configJSON.callApiContentType,
      "token": document.cookie.split("; ").find((row) => row.startsWith("Token="))?.split("=")[1]
    };


    const reportDataComparisonMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportDataSelectId = reportDataComparisonMessage.messageId;

    reportDataComparisonMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.invoiceAssociatedList}?project_id=${this.state.projectId}`
    );

    reportDataComparisonMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    reportDataComparisonMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(reportDataComparisonMessage.id, reportDataComparisonMessage);
    return true;
  }

  receiveReportData = (responseJson: any) => {
    if (responseJson) {
      const reportsData = responseJson.reports.map((item: any) => {
        return {
          id: item[0],
          value: item[1],
        };
      });

      this.setState({ reportDropDown: reportsData, reportFilteredData: reportsData })
    }

  }

  handleReportSerach = (event: any) => {
    const query = event.target.value;
    this.setState({ searchReportQuery: query }, () => {
      const reportFilteredData = this.state.reportDropDown.filter((item: { value: string; }) =>
        item.value.toLowerCase().includes(query.toLowerCase())
      );
      this.setState({ reportFilteredData });
    });
  };

  handleEmptyValue = (e: any) => {
    if (!this.state.projectValue || !this.state.ReportValue) {
      e.preventDefault();
      this.setState({
        projectEmptyValue: !!!this.state.projectValue,
        reportEmptyValue: !!!this.state.ReportValue,
      });
    }
  };

  selectProject = () => {
    this.setState({
      uploadInvoiceProjectDataFlag: !this.state.uploadInvoiceProjectDataFlag,
      projectEmptyValue: false,
      ReportDataFlag: false,
      searchProjectQuery: ""
    }, () => { this.projectSelectData() });
  };

  selectReport = () => {
    if(this.state.projectId){
      this.setState({
        ReportDataFlag: !this.state.ReportDataFlag,
        reportEmptyValue: false,
        uploadInvoiceProjectDataFlag: false,
        searchReportQuery: ""
      }, () => { this.reportSelectData() });
    }
  };


  projectSearch = (event: { target: { value: string } }) => {
    const newValue = event.target.value;
    this.setState({ projectSearchValue: newValue });
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId as number);
    }

    if (newValue.length === 0) {
      this.getProjects();
    } else {
      const timeoutId = setTimeout(() => {
        this.projectSearchApiFunction(newValue);
      }, 1000);
      
      this.setState({ timeoutId }); 
    }
  };

  projectSearchApiFunction = async (query: string) => {
    const trimmedQuery = query.trim();
    this.setState({isLoading: true, isPageActive : true });

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.getToken()
    };

    const projectRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.projectSearchApiCallId = projectRequestMessage.messageId;

    projectRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.projectSearchApiEndPoint}${trimmedQuery}`
    );

    projectRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    projectRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );

    runEngine.sendMessage(projectRequestMessage.id, projectRequestMessage);

    return true;
  };

  handleCloseModal = () => {
    this.setState({ openAddNameDialog: false, inputName: '', addNameErrMsg: '' });
};

  // Customizable Area End
}
